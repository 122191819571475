interface KeyValueObject {
    [key: string]: string;
}

/*
 * User Roles
 */
export const USER_ROLES = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    MANAGER: "MANAGER",
    USER: "USER"
};
