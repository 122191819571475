import { getAuthToken } from "../session/auth";
import axios, { Method } from "axios";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";
const PATCH = "patch";

const UNAUTHORIZED = "Unauthorized.";
const PERMISSION_DENIED = "Permission Denied.";

const request = (
    url: string,
    type: Method = GET,
    data: object = {},
    params: object = {},
    headers: any = { "Content-Type": "application/json" }
) => {
    let baseUrl = process.env.REACT_APP_SERVER_HOST;
    const routePath = baseUrl + (baseUrl?.slice(-1) === "/" ? "" : "/") + url;

    headers["Authorization"] = getAuthToken();

    const options = {
        method: type,
        url: routePath,
        data,
        params,
        headers
    };

    return axios(options)
        .then((result: any) => {
            return result.data;
        })
        .catch(function(error: any) {
            // TODO handle error according to respone
            if (error.response && error.response.status === 401) {
                //TODO:: logout user
                throw new Error(UNAUTHORIZED);
            } else if (error.response && error.response.status === 403) {
                //TODO:: direct to permission denied page
                throw new Error(PERMISSION_DENIED);
            } else if (error.response && error.response.status !== 500) {
                const msg =
                    "message" in error.response.data
                        ? error.response.data.message
                        : "Error occurred.";
                throw new Error(msg);
            } else {
                throw new Error("Error occurred.");
            }
        });
};

export { request, GET, POST, PUT, DELETE, PATCH, UNAUTHORIZED, PERMISSION_DENIED };
