/**
 * Custom Progress Indicator
 *
 * Usage:
 *
 * Ex:
 *    import ProgressIndicator from "components/ui/progressIndicator";
 *    ....
 *    <ProgressIndicator type="linear" />
 * ...
 */
import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import CircularProgress, {
    CircularProgressProps
} from "@material-ui/core/CircularProgress";
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import Backdrop from "@material-ui/core/Backdrop";

interface BaseProgressIndicatorProps {
    /**
     * Type of the Progress Indicator.
     * This can be "circular" or "linear"
     */
    type: "circular" | "linear";
    backdrop?: boolean;
}

interface CircularProgressIndicatorProps
    extends CircularProgressProps,
        BaseProgressIndicatorProps {}

interface LinearProgressIndicatorProps
    extends LinearProgressProps,
        BaseProgressIndicatorProps {}

type ProgressIndicatorProps =
    | CircularProgressIndicatorProps
    | LinearProgressIndicatorProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            alignItems: "center",
            "& > * + *": {
                marginTop: theme.spacing(1)
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff"
        }
    })
);

const ProgressIndicator: FC<ProgressIndicatorProps> = ({ type, backdrop }) => {
    const classes = useStyles();
    return backdrop ? (
        <Backdrop className={classes.backdrop} open={true}>
            <div className={classes.root}>
                {type === "circular" ? <CircularProgress /> : <LinearProgress />}
            </div>
        </Backdrop>
    ) : (
        <div className={classes.root}>
            {type === "circular" ? <CircularProgress /> : <LinearProgress />}
        </div>
    );
};

export default ProgressIndicator;
