import { useEffect, useState } from "react";

import { request, POST } from "api/adapter";

import { pick, isArray, isEmpty } from "lodash";
import { isNull, isUndefined } from "util";

import { KeyValueObject } from "common";

import { USER_ROLES } from "constants/index";

const SESSION_KEY: string = "user-session"; // local-storage key for save session

const RESPONSE_LOGIN_FAIL = "Failed to login";

type roleTypes = keyof typeof USER_ROLES;

export interface IAuthParams {
    email: string;
    password: string;
}

interface ILoginResponseData {
    token: string;
    user: KeyValueObject;
}

interface ILoginResponse {
    error?: boolean;
    message?: string;
    data: ILoginResponseData;
}

export interface IUserSession {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isClientActive: boolean;
    token: string;
    role: { id: string; name: roleTypes; permissions: any; description: string } | null;
    profileImage: string;
}

export let authenticated = false; // Current authentication status of the logged in user

let userSession: IUserSession = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    isClientActive: false,
    token: "",
    role: null,
    profileImage: ""
};

export let userRoleType: string = USER_ROLES["USER"]; //  Role type of the logged in user

/**
 * Login authentication function
 * @param authParams - email, password contained object
 */
export const login = async (authParams: IAuthParams) => {
    try {
        const response: ILoginResponse = await request(`login`, POST, authParams);
        if (!response.error) {
            createSession(response.data);
            return response.data.user;
        } else {
            throw Error(RESPONSE_LOGIN_FAIL);
        }
    } catch (error) {
        throw Error(error.message);
    }
};

/**
 * Updates the global scope user object with session data.
 * @param session - session object.
 */
const updateUserSession = (session: KeyValueObject) => {
    userSession.id = session && session.id ? session.id : "";
    userSession.email = session && session.email ? session.email : "";
    userSession.firstName = session && session.firstName ? session.firstName : "";
    userSession.lastName = session && session.lastName ? session.lastName : "";
    userSession.isClientActive =
        session && session.isClientActive ? session.isClientActive : false;
    userSession.token = session && session.token ? session.token : "";
    userSession.role = session && session.role ? session.role : "";
    userSession.profileImage =
        session && session.profileImage ? session.profileImage : "";
};

/**
 * Erase all keys of global scope user object
 */
const deleteUserSessionKeys = () => {
    for (const prop of Object.getOwnPropertyNames(userSession)) {
        delete userSession[prop];
    }
};

/**
 * Remove session object localStorage data on logout.
 * @param routerHistory - History<History.PoorMansUnknown> object for routing (TODO: type is kept as any for now)
 * @param redirect - Redirecting path
 */
export const logout = (routerHistory: any, redirect?: string) => {
    deleteUserSessionKeys();
    localStorage.removeItem(SESSION_KEY);
    authenticated = false;
    if (redirect) {
        routerHistory.push(redirect);
    }
};

/**
 * Creates a session object in localstorage.
 * @param responseData - Response Data from authentication
 */
const createSession = (responseData: ILoginResponseData) => {
    const { token, user } = pick(responseData, ["token", "user"]);
    const session = { token, ...user };
    updateUserSession(session);
    localStorage.setItem(SESSION_KEY, JSON.stringify(userSession));
    authenticated = true;
    userRoleType = String(user.role.name);
};

/**
 * Checks if token exists in localstorage & updates authenticated flag.
 */
export const checkAuth = () => {
    const session = getSession();
    if (session && !isUndefined(session)) {
        authenticated = session.hasOwnProperty("token") && session.token ? true : false;
    } else {
        authenticated = false;
    }
};

/**
 * Check & update the use role type
 */
export const checkUserRole = () => {
    userRoleType = userRole();
};

/**
 * Returns the auth JWT token
 * @param isBearerToken - Flag to determine whether to return in "Bearer {TOKEN}" format.
 */
export const getAuthToken = (isBearerToken: boolean = false) => {
    const session = getSession();
    const token = session && session.hasOwnProperty("token") ? session.token : "";
    // if (!isBearerToken) {
    //   return token;
    // }
    return `Bearer ${token}`;
};

/**
 * Returns the user role.
 */
export const userRole = (): string => {
    const session = getSession();
    let ROLE = "";
    if (session && !isUndefined(session)) {
        ROLE = session.role && session.role.name ? session.role.name : USER_ROLES["USER"];
    } else {
        ROLE = USER_ROLES["USER"];
    }
    return ROLE;
};

/**
 * Returns the session object stored in localStorage
 */
export const getSession = (): any => {
    let sessionJson: any = localStorage.getItem(SESSION_KEY);
    if (sessionJson && !isNull(sessionJson)) {
        const session = JSON.parse(sessionJson);
        return session;
    }
    return null;
};

/**
 * React hook to handle session from browser storage
 * @param sessionKey - Browser storage key. Default is set to the key `user-session`
 * @returns If there is a session in your browser storage then it will be returned. If there is no session, it will return null.
 *
 * Usage - This hook is useful in scenarios where you need to listen to session changes in functional components
 *
 * import { useSession } from "session/auth";
 *
 * ...
 * <FC>
 *  const { session } = useSession();
 * </FC>
 */
export const useSession = () => {
    const [state, setState] = useState<IUserSession>(getSession);

    const syncState = (event: StorageEvent) => {
        if (event.key === SESSION_KEY) {
            setState(getSession);
        }
    };

    /**
     * Muatate/update session key
     * @param key = Session key to be updated
     * @param value = Updating value
     */
    const updateSessionKey = (key: keyof IUserSession, value: any) => {
        const sessionObject = state;
        sessionObject[`${key}`] = value;
        localStorage.setItem(SESSION_KEY, JSON.stringify(sessionObject));
    };

    useEffect(() => {
        window.addEventListener("storage", syncState);
        return () => {
            window.removeEventListener("storage", syncState);
        };
    }, []);

    return { session: state, updateSessionKey };
};
