import React, { FC, Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    HashRouter,
    Switch,
    Redirect,
    Route
} from "react-router-dom";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// Date util library
import MomentUtils from "@date-io/moment";

import themeSwitcher from "assets/styles/theme";
import "assets/css/App.css";

import PrivateRoute from "components/routes/Auth/PrivateRoute";
import AuthRoute from "components/routes/Auth/AuthRoute";
import TopAppbarCtx from "components/layout/TopAppbar/TopAppbarContext";

import ProgressIndicator from "components/ui/progressIndicator";

import { SnackbarCtxProvider } from "components/layout/Snackbar";

import { authenticated } from "session/auth";

/* Core components */
const Landing = lazy(() => import("views/Landing"));
const Dashboard = lazy(() => import("views/Dashboard"));
const Login = lazy(() => import("views/Auth/SignIn"));
const SignUp = lazy(() => import("views/Auth/SignUp"));
const ForgottenPwd = lazy(() => import("views/Auth/ForgotPassword"));
const ChangePassword = lazy(() => import("views/Auth/ChangePassword"));
const ChangeInitialPassword = lazy(() => import("views/Auth/ChangeInitialPassword"));
const PaymentSuccess = lazy(() => import("views/Payments/Success"));
const PaymentFail = lazy(() => import("views/Payments/Fail"));

const App: FC = () => {
    return (
        <ThemeProvider theme={themeSwitcher("HRC")}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="App">
                    <SnackbarCtxProvider>
                        <Router>
                            <HashRouter>
                                <Suspense fallback={<ProgressIndicator type="linear" />}>
                                    <Switch>
                                        <AuthRoute
                                            exact
                                            path="/"
                                            component={Landing}
                                            isAuthenticated={authenticated}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/login"
                                            component={Login}
                                            isAuthenticated={authenticated}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/signup"
                                            component={SignUp}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/ForgottenPassword"
                                            component={ForgottenPwd}
                                        />
                                        <Route exact path="/change-password/:id/:token">
                                            <ChangePassword />
                                        </Route>
                                        <TopAppbarCtx>
                                            <PrivateRoute
                                                path="/dashboard"
                                                component={Dashboard}
                                                isAuthenticated={authenticated}
                                            />
                                            <PrivateRoute
                                                path="/change-initial-password"
                                                component={ChangeInitialPassword}
                                                isAuthenticated={authenticated}
                                            />
                                            <PrivateRoute
                                                path="/payment-success"
                                                component={PaymentSuccess}
                                                isAuthenticated={authenticated}
                                            />
                                            <PrivateRoute
                                                path="/payment-faild"
                                                component={PaymentFail}
                                                isAuthenticated={authenticated}
                                            />
                                        </TopAppbarCtx>
                                        {/* Redirect to "URL/#/login" on invalid paths entered as "URL/#/INVALID" */}
                                        <Redirect from="/" to="/login" />
                                        {/* Redirect to "URL/#/login" on invalid paths entered as "URL/#/login/INVALID" */}
                                        <Redirect from="/login" to="/login" />
                                    </Switch>
                                </Suspense>
                            </HashRouter>
                        </Router>
                    </SnackbarCtxProvider>
                </div>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
