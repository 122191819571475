import { createMuiTheme } from "@material-ui/core/styles";

const THEMES = ["THEME_1", "HRC"] as const;
type ThemeTypes = typeof THEMES[number];

/* Intial color theme implementation */
const THEME_1 = createMuiTheme({
    palette: {
        primary: {
            main: "#FFBF00",
            light: "#FDD766",
            dark: "#F29F05",
            contrastText: "#000"
        },
        secondary: {
            main: "#3F3F3F",
            light: "#E4E4E4",
            dark: "#0D0D0C",
            contrastText: "#fff"
        },
        error: {
            light: "#ff5252",
            main: "#ff1744",
            dark: "#d50000"
        },
        warning: {
            light: "#ff5252",
            main: "#ff1744",
            dark: "#d50000"
        },
        background: {
            default: "#E4E4E4",
            paper: "#fff"
        },
        text: {},
        action: {
            hover: "#FFBF00",
            selected: "#F29F05"
        }
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: "#000",
                "&$focused": {
                    color: "#000"
                }
            }
        },
        MuiCard: {
            root: {
                borderRadius: "10px"
            }
        }
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
});

const HRC = createMuiTheme({
    palette: {
        primary: {
            main: "#335B84",
            light: "#2096C2",
            dark: "#335B84",
            contrastText: "#000"
        },
        secondary: {
            main: "#3F3F3F",
            light: "#E4E4E4",
            dark: "#0D0D0C",
            contrastText: "#fff"
        },
        error: {
            light: "#ff5252",
            main: "#ff1744",
            dark: "#d50000"
        },
        warning: {
            light: "#ff5252",
            main: "#ff1744",
            dark: "#d50000"
        },
        background: {
            default: "#E4E4E4",
            paper: "#fff"
        },
        text: {},
        action: {
            hover: "#2096C2",
            selected: "#335B84"
        }
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: "#000",
                "&$focused": {
                    color: "#000"
                }
            }
        },
        MuiCard: {
            root: {
                borderRadius: "3px"
            }
        }
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
});

const themeSwitcher = (theme: ThemeTypes) => {
    switch (theme) {
        case "THEME_1":
            return THEME_1;
        case "HRC":
            return HRC;
        default:
            return THEME_1;
    }
};

export default themeSwitcher;
