import React, {
    FC,
    createContext,
    Dispatch,
    ReactNode,
    useState,
    SetStateAction
} from "react";

import { useSession } from "session/auth";

interface ITopAppBarContextProps {
    /**
     * Current app-bar heading
     */
    heading: string;

    /**
     * Profile avatar/image
     */
    profileAvatar: string;

    /**
     * Updates the heading in app-bar level
     */
    setHeading: (heading: string) => void | Dispatch<SetStateAction<string>>;

    /**
     * Update the profile avatar
     */
    setProfileAvatar: (imageString: string) => void;
}
const defaultHeading = "Dashboard";

const initialContext: ITopAppBarContextProps = {
    heading: defaultHeading,
    profileAvatar: "",
    setHeading: () => {},
    setProfileAvatar: () => {}
};

export const TopAppBarContext = createContext<ITopAppBarContextProps>(initialContext);

interface ITopAppBarCtxProviderProps {
    children: ReactNode;
}

const TopAppBarCtxProvider: FC<ITopAppBarCtxProviderProps> = ({ children }) => {
    const { session, updateSessionKey } = useSession();

    /**
     * TopAppbar Header
     */
    const [heading, setHeading] = useState(defaultHeading);

    /**
     * Profile avatar/image
     */
    const [profileAvatar, setProfileAvatar] = useState(
        session && session.profileImage ? session.profileImage : ""
    );

    /**
     * Update the profile image
     * @param imageString - Base64 string of the image
     */
    const updateProfileAvatar = (imageString: string) => {
        setProfileAvatar(imageString);
        updateSessionKey("profileImage", imageString);
    };

    return (
        <TopAppBarContext.Provider
            value={{
                heading,
                profileAvatar,
                setHeading,
                setProfileAvatar: updateProfileAvatar
            }}>
            {children}
        </TopAppBarContext.Provider>
    );
};

export default TopAppBarCtxProvider;
